import { Button, TextInput, Typography, ValidationRules } from '@astrid/components'
import React, { useCallback, useContext, useState } from 'react'

import { ScreenNames, trackAction, trackButtonClickEvent } from 'analytics/analytics'
import PageWrapper from 'components/PageWrapper'
import { useFormik } from 'formik'
import { handleAuthError } from 'helpers/utils'
import useTypedTranslator from 'hooks/useTypedTranslator'
import { ROUTES } from 'navigation/types'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { selectProfileData } from 'store/services/Auth/auth.reducer'
import { ThunkDispatch } from 'store/types'
import * as Yup from 'yup'

import { useTrackScreenShown } from 'hooks'
import { AppContext } from '../../components/AppContext/AppContext'
import styles from './SignUp.module.scss'
import { SocialSignUpMethod } from '../../store/services/Auth/types'
import SocialLogInButton from '../../components/SocialLogInButton'
import Divider from '../../components/Divider'

const validationSchema = Yup.object().shape({
  email: ValidationRules.email,
  password: ValidationRules.required,
  passConfirm: Yup.string()
    .oneOf([Yup.ref('password'), ''], "Passwords don't match")
    .required('Required')
})

interface FormValues {
  email: string
  password: string
  passConfirm: string
}

function SignUp() {
  const { authActions } = useContext(AppContext)
  const profileData = useSelector(selectProfileData)
  const dispatch = useDispatch<ThunkDispatch>()
  const t = useTypedTranslator()
  const [error, setError] = useState<string | null>(null)

  useTrackScreenShown(ScreenNames.SignUp)

  const onSignUpClick = useCallback(
    async ({ email, password }: FormValues /*, { setSubmitting }: FormikHelpers<FormValues>*/) => {
      setError(null)
      if (profileData) {
        try {
          await dispatch(authActions.signUp({ email, password, profile: profileData }))
          trackAction({ actionName: 'Account creation confirmed', actionValue: 'email' })
        } catch (error) {
          setError(handleAuthError(error))
          setSubmitting(false)
        }
      }
    },
    // eslint-disable-next-line
    []
  )

  const { values, touched, errors, handleChange, setFieldTouched, isSubmitting, handleSubmit, setSubmitting } =
    useFormik<FormValues>({
      initialValues: { email: '', password: '', passConfirm: '' },
      validationSchema: validationSchema,
      onSubmit: onSignUpClick
    })

  const onSocialSignUp = (variant: SocialSignUpMethod) => async () => {
    trackButtonClickEvent({ componentName: 'Social sign up button', componentValue: variant })
    setError(null)
    setSubmitting(true)
    try {
      await dispatch(authActions.socialSignUp(variant))
      trackAction({ actionName: 'Account creation confirmed', actionValue: variant })
    } catch (error) {
      setSubmitting(false)
      setError(handleAuthError(error))
    }
  }

  if (!profileData) {
    return <Redirect to={ROUTES.LEARNER_PROFILE} />
  }

  return (
    <PageWrapper>
      <div className={styles.header}>
        <Typography variant="h2">{t('general.sign_up')}</Typography>
        <Typography variant="body">{t('sign_up.create_profile')}</Typography>
      </div>

      {/* <SocialLogInButton variant="fb" onClick={onSocialSignUp(SocialSignUpMethod.FACEBOOK)} /> */}
      <SocialLogInButton variant="google" onClick={onSocialSignUp(SocialSignUpMethod.GOOGLE)} />
      <SocialLogInButton variant="apple" onClick={onSocialSignUp(SocialSignUpMethod.APPLE)} />

      <Divider text={t('general.or')} withoutLines small />

      <div className={styles.input_container}>
        <TextInput
          name="email"
          label={t('general.email')}
          value={values.email}
          onChange={handleChange('email')}
          error={touched.email && !!errors.email}
          helperText={(touched.email && errors.email) || ''}
          onBlur={() => setFieldTouched('email')}
        />
        <TextInput
          name="password"
          label={t('general.password')}
          type="password"
          value={values.password}
          onChange={handleChange('password')}
          error={touched.password && !!errors.password}
          helperText={(touched.password && errors.password) || ''}
          onBlur={() => setFieldTouched('password')}
        />
        <TextInput
          name="passConfirm"
          label={t('general.confirm_pass')}
          type="password"
          value={values.passConfirm}
          onChange={handleChange('passConfirm')}
          error={touched.passConfirm && !!errors.passConfirm}
          helperText={(touched.passConfirm && errors.passConfirm) || ''}
          onBlur={() => setFieldTouched('passConfirm')}
        />
      </div>

      {error ? (
        <Typography variant="body" className={styles.error}>
          {error}
        </Typography>
      ) : null}

      <Button
        type="submit"
        color="light-blue"
        disabled={isSubmitting}
        className={styles.button}
        onClick={() => {
          trackButtonClickEvent({ componentName: 'Sign up button' })
          handleSubmit()
        }}>
        {t('general.sign_up')}
      </Button>

      <Typography variant="body" className={styles.margin}>
        {t('sign_up.already_have')}{' '}
        <Link to={ROUTES.LOG_IN} color="#000">
          <u>{t('sign_up.here')}</u>
        </Link>
        .
      </Typography>
    </PageWrapper>
  )
}

export default SignUp
