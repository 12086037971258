import React from 'react'
import { Route, RouteProps } from 'react-router'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'

import { selectPhoneNumber, selectUser } from 'store/services/Auth/auth.reducer'
import { ROUTES } from './types'

type ProtectedRouteProps = RouteProps

function ProtectedRoute(props: ProtectedRouteProps) {
  const user = useSelector(selectUser)
  const phoneNumber = useSelector(selectPhoneNumber)

  if (user || phoneNumber) {
    return <Route {...props} />
  } else {
    return <Redirect to={ROUTES.LOG_IN} />
  }
}

export default ProtectedRoute
