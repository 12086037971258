import React, { useContext, useEffect, useState } from 'react'

import { Button, countries, Typography } from '@astrid/components'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'store/types'

import { MenuItem, Select } from '@material-ui/core'
import InlineInput from 'components/InlineInput'
import PageWrapper from 'components/PageWrapper'
import { handleAuthError } from 'helpers/utils'
import useTypedTranslator from 'hooks/useTypedTranslator'
import { ROUTES } from 'navigation/types'
import { useHistory } from 'react-router-dom'
import ct from 'countries-and-timezones'
import styles from './PhoneVerification.module.scss'
import { ScreenNames, trackButtonClickEvent, trackOptionSelectEvent } from 'analytics/analytics'
import { useTrackScreenShown } from 'hooks'
import { AppContext } from '../../components/AppContext/AppContext'

function PhoneVerification() {
  const { authActions } = useContext(AppContext)
  const dispatch = useDispatch<ThunkDispatch>()
  const [error, setError] = useState<string | null>(null)
  const history = useHistory()
  const t = useTypedTranslator()
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [countryCodeIndex, setCountryCodeIndex] = useState<number | string>('')

  useEffect(() => {
    if (window.Intl) {
      const countryCode = ct.getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)?.id || ''
      const index = countries.findIndex((country) => country.code === countryCode)
      index > -1 && setCountryCodeIndex(index)
    }
  }, [])

  useTrackScreenShown(ScreenNames.LogInPhoneNumber)

  const handleSelectCountry = (index: number) => {
    trackOptionSelectEvent({ componentName: 'Country selector', componentValue: countries[index].name })
    setCountryCodeIndex(index)
  }

  const handleSubmit = async () => {
    trackButtonClickEvent({ componentName: 'Verify account button' })

    if (countryCodeIndex === '') {
      setError(t('error.missing_country_code'))
      return
    }

    if (!phoneNumber) {
      setError(t('error.missing_phone_number'))
      return
    }

    try {
      const completePhoneNumber = `${
        countryCodeIndex !== '' ? countries[countryCodeIndex].callingCode : ''
      }${phoneNumber}`
      const normalizedNumber = await dispatch(authActions.verifyPhoneNumber(completePhoneNumber.trim()))
      if (normalizedNumber) {
        history.push(ROUTES.CODE_REDEEM)
      } else {
        setError(t('error.phone_number_not_valid'))
      }
    } catch (error) {
      setError(handleAuthError(error))
    }
  }

  return (
    <PageWrapper>
      <div className={styles.header}>
        <Typography variant="h1">{t('general.verify_phone_number')}</Typography>
        <Typography variant="body">{t('general.redeem_code_with_phone')}</Typography>
      </div>
      <InlineInput
        name="phone"
        className={styles.input}
        placeholder={t('general.phone_number')}
        autoFocus
        label={
          <Select
            disableUnderline
            displayEmpty
            className={styles.selector}
            value={countryCodeIndex ?? ''}
            onChange={(event) => handleSelectCountry(event.target.value as number)}>
            <MenuItem value="" disabled>
              <span className={styles.selectText}>{t('general.country')}</span>
            </MenuItem>
            {countries.map((country, index) => (
              <MenuItem value={index} key={country.code}>
                <div className={styles.option}>
                  <span className={styles.flip}>
                    <span className={styles.name}>{country.name}</span>
                    <span className={styles.flag}>{country.flagEmoji}</span>{' '}
                  </span>
                  <span className={styles.code}>{country.callingCode}</span>
                </div>
              </MenuItem>
            ))}
          </Select>
        }
        type="number"
        value={phoneNumber}
        onChange={(event) => setPhoneNumber(event.target.value)}
      />

      {error ? (
        <Typography variant="body" className={styles.error}>
          {error}
        </Typography>
      ) : null}

      <Button color="green" className={styles.button} onClick={handleSubmit}>
        {t('general.verify')}
      </Button>
    </PageWrapper>
  )
}

export default PhoneVerification
