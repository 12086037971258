import React, { InputHTMLAttributes } from 'react'
import cx from 'classnames'

import styles from './InlineInput.module.scss'

interface InlineInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element
}

function InlineInput({ label, className, ...rest }: InlineInputProps) {
  const _className = cx(styles.container, className)
  return (
    <div className={_className}>
      <div className={styles.badge}>{label}</div>
      <div className={styles.divider} />
      <input className={styles.input} {...rest} />
    </div>
  )
}

export default InlineInput
