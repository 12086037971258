import { Typography } from '@astrid/components'
import React from 'react'

import styles from './SubscriptionCheckTutorial.module.scss'

import TutorialStep1 from 'assets/tutorial-step-1.png'
import TutorialStep2 from 'assets/tutorial-step-2.png'
import TutorialStep3 from 'assets/tutorial-step-3.png'
import TutorialStep4 from 'assets/tutorial-step-4.png'
import useTypedTranslator from 'hooks/useTypedTranslator'
import { ScreenNames } from 'analytics/analytics'
import { useTrackScreenShown } from 'hooks'

function SubscriptionCheckTutorial() {
  const t = useTypedTranslator()

  useTrackScreenShown(ScreenNames.SubscriptionStatusCheckTutorial)

  const STEPS = [
    {
      text: [t('check_tutorial.open_astrid'), t('check_tutorial.click_profile')],
      img: TutorialStep1
    },
    {
      text: [t('check_tutorial.click_settings')],
      img: TutorialStep2
    },
    {
      text: [t('check_tutorial.open_subscription')],
      img: TutorialStep3
    },
    {
      text: [t('check_tutorial.check_subscription')],
      img: TutorialStep4
    }
  ]

  return (
    <div className={styles.container}>
      <Typography variant="h2" className={styles.header}>
        {t('check_tutorial.how_to_check')}
      </Typography>

      {STEPS.map((step, i) => (
        <div className={styles.steps_container} key={`step${i}`}>
          <div className={styles.step}>
            <div className={styles.step_text_container}>
              {step.text.map((txt) => (
                <Typography variant="body" key={txt}>
                  {txt}
                </Typography>
              ))}
            </div>
            <img src={step.img} className={styles.step_img} alt="app screenshot" />
          </div>
        </div>
      ))}
    </div>
  )
}

export default SubscriptionCheckTutorial
