import React, { useCallback, useContext, useState } from 'react'
import { Button, TextInput, Typography } from '@astrid/components'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'store/types'
import PageWrapper from 'components/PageWrapper'
import { FormikHelpers, useFormik } from 'formik'
import { handleAuthError } from 'helpers/utils'
import useTypedTranslator from 'hooks/useTypedTranslator'
import { ROUTES } from 'navigation/types'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { ValidationRules } from '@astrid/components'
import styles from './EmailLogin.module.scss'
import { ScreenNames, trackButtonClickEvent } from 'analytics/analytics'
import { useTrackScreenShown } from 'hooks'
import { AppContext } from '../../components/AppContext/AppContext'

const validationSchema = Yup.object().shape({
  email: ValidationRules.email.trim(),
  password: ValidationRules.required
})

interface FormValues {
  email: string
  password: string
}

function EmailLogin() {
  const { authActions } = useContext(AppContext)
  const dispatch = useDispatch<ThunkDispatch>()
  const [error, setError] = useState<string | null>(null)
  const history = useHistory()
  const t = useTypedTranslator()

  useTrackScreenShown(ScreenNames.LogInEmail)

  const onLoginPress = useCallback(
    async ({ email, password }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      setSubmitting(true)
      setError(null)
      try {
        await dispatch(authActions.logIn(email.trim(), password))
        history.push(ROUTES.CODE_REDEEM)
      } catch (error) {
        setError(handleAuthError(error))
        setSubmitting(false)
      }
    },
    [dispatch, history, authActions]
  )

  const { values, touched, errors, handleChange, setFieldTouched, isSubmitting, submitForm } = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: validationSchema,
    onSubmit: onLoginPress
  })

  return (
    <PageWrapper>
      <div className={styles.header}>
        <Typography variant="h1">{t('general.log_in')}</Typography>
        <Typography variant="body">{t('login.log_in_using_email')}</Typography>
      </div>

      <form className={styles.form}>
        <TextInput
          name="email"
          label={t('general.email')}
          type="email"
          value={values.email}
          onChange={handleChange('email')}
          error={touched.email && !!errors.email}
          helperText={(touched.email && errors.email) || ''}
          onBlur={() => setFieldTouched('email')}
        />
        <TextInput
          name="password"
          label={t('general.password')}
          type="password"
          value={values.password}
          onChange={handleChange('password')}
          error={touched.password && !!errors.password}
          helperText={(touched.password && errors.password) || ''}
          onBlur={() => setFieldTouched('password')}
        />

        {error ? (
          <Typography variant="body" className={styles.error}>
            {error}
          </Typography>
        ) : null}

        <Button
          type="submit"
          color="green"
          disabled={isSubmitting}
          className={styles.button}
          onClick={(e) => {
            e.preventDefault()
            trackButtonClickEvent({ componentName: 'Login button' })
            submitForm()
          }}>
          {t('general.log_in')}
        </Button>
      </form>
    </PageWrapper>
  )
}

export default EmailLogin
