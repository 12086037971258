import { Dispatch } from 'redux'
import { AuthApi, AuthTokenAccessor } from './auth.api'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCredential,
  signOut as firebaseSignOut,
  FacebookAuthProvider
} from 'firebase/auth'

import { RootState } from 'store/types'

import * as Types from './types'

type AuthState = {
  user: Types.User | null
  normalizedPhoneNumber: string | null
  tempProfileData: Types.CreateProfileData | null
}

export const authInitialState: AuthState = {
  user: null,
  normalizedPhoneNumber: null,
  tempProfileData: null
}

export const authReducer = (state: AuthState = authInitialState, action: Types.AuthActionsType): AuthState => {
  switch (action.type) {
    case Types.AUTH_ACTIONS.ClearUser: {
      return authInitialState
    }

    case Types.AUTH_ACTIONS.LoadUser: {
      return {
        ...state,
        user: action.user
      }
    }

    case Types.AUTH_ACTIONS.LoadPhoneNumber: {
      return {
        ...state,
        normalizedPhoneNumber: action.normalizedPhoneNumber
      }
    }

    case Types.AUTH_ACTIONS.ClearPhoneNumber: {
      return authInitialState
    }

    case Types.AUTH_ACTIONS.SetTempProfileData: {
      return {
        ...state,
        tempProfileData: action.profileData
      }
    }

    default:
      return state
  }
}

const googleProvider = new GoogleAuthProvider()
const facebookProvider = new FacebookAuthProvider()
facebookProvider.addScope('email')
facebookProvider.addScope('public_profile')

/** @param {string} token should be a token generated for chosen method */
const getUserFirebaseIdToken = async (method: Types.SocialSignUpMethod) => {
  const auth = getAuth()
  switch (method) {
    case Types.SocialSignUpMethod.APPLE: {
      // const appleAuthRequestResponse = await appleAuth.performRequest({
      //   requestedOperation: appleAuth.Operation.LOGIN,
      //   requestedScopes: [appleAuth.Scope.EMAIL]
      // })
      // if (!appleAuthRequestResponse.identityToken) throw 'Apple Sign-In failed - no identify token returned'
      // const { identityToken, nonce } = appleAuthRequestResponse
      // const appleCredential = auth.AppleAuthProvider.credential(identityToken, nonce)
      // const { user } = await auth().signInWithCredential(appleCredential)
      // const firebaseIdToken = await user.getIdToken()
      // return firebaseIdToken
      return ''
    }

    case Types.SocialSignUpMethod.FACEBOOK: {
      await firebaseSignOut(auth)
      const result = await signInWithPopup(auth, facebookProvider)
      const fbCredential = FacebookAuthProvider.credentialFromResult(result)
      if (fbCredential) {
        const { user } = await signInWithCredential(auth, fbCredential)
        const firebaseIdToken = await user.getIdToken()
        return firebaseIdToken
      } else {
        throw new Error('Failed to obtain Firebase Token Id with Facebook')
      }
    }

    case Types.SocialSignUpMethod.GOOGLE: {
      await firebaseSignOut(auth)
      const result = await signInWithPopup(auth, googleProvider)
      const googleCredential = GoogleAuthProvider.credentialFromResult(result)
      if (googleCredential) {
        const { user } = await signInWithCredential(auth, googleCredential)
        const firebaseIdToken = await user.getIdToken()
        return firebaseIdToken
      } else {
        throw new Error('Failed to obtain Firebase Token Id with Google')
      }
    }

    default:
      throw new Error('Missing sign up method')
  }
}

// ACTION CREATORS:
export class AuthActions {
  public constructor(private readonly authApi: AuthApi, private readonly authTokenAccessor: AuthTokenAccessor) {}

  public signOut = (dispatch: Dispatch<Types.ActionClearUser>) => {
    this.authTokenAccessor.removeUserToken()
    dispatch({ type: Types.AUTH_ACTIONS.ClearUser })
  }

  public socialSignUp = (method: Types.SocialSignUpMethod) => {
    return async (dispatch: Dispatch<Types.ActionLoadUser>, getState: () => RootState) => {
      const {
        auth: { tempProfileData }
      } = getState()

      if (tempProfileData) {
        const firebaseIdToken = await getUserFirebaseIdToken(method)
        const { token, user } = await this.authApi.socialSignUp(firebaseIdToken, tempProfileData, method)
        this.authTokenAccessor.setUserToken(token)
        dispatch({ type: Types.AUTH_ACTIONS.LoadUser, user })
      }
    }
  }

  public socialLogin = (method: Types.SocialSignUpMethod) => {
    return async (dispatch: Dispatch<Types.ActionLoadUser>) => {
      const firebaseIdToken = await getUserFirebaseIdToken(method)
      const { token, user } = await this.authApi.socialLogin(firebaseIdToken)
      this.authTokenAccessor.setUserToken(token)
      dispatch({ type: Types.AUTH_ACTIONS.LoadUser, user })
    }
  }

  public logIn = (username: string, password: string) => {
    return async (dispatch: Dispatch<Types.ActionLoadUser>) => {
      const { token, user } = await this.authApi.logIn(username, password)
      this.authTokenAccessor.setUserToken(token)
      dispatch({ type: Types.AUTH_ACTIONS.LoadUser, user })
    }
  }

  public verifyPhoneNumber = (phoneNumber: string) => {
    return async (dispatch: Dispatch<Types.ActionLoadPhoneNumber>) => {
      const { normalizedPhoneNumber } = await this.authApi.verifyPhoneNumber(phoneNumber)
      dispatch({ type: Types.AUTH_ACTIONS.LoadPhoneNumber, normalizedPhoneNumber })
      return normalizedPhoneNumber
    }
  }

  public signUp = (data: Types.SignUpData) => {
    return async (dispatch: Dispatch<Types.ActionLoadUser>) => {
      const { user, token } = await this.authApi.signup(data)
      this.authTokenAccessor.setUserToken(token)
      dispatch({ type: Types.AUTH_ACTIONS.LoadUser, user })
    }
  }

  public getUser = () => {
    return async (dispatch: Dispatch<Types.ActionLoadUser>) => {
      const user = await this.authApi.getUser()
      dispatch({ type: Types.AUTH_ACTIONS.LoadUser, user })
    }
  }

  public setTempProfileData = (data: Types.CreateProfileData | null) => {
    return (dispatch: Dispatch<Types.ActionSetTempProfileData>) => {
      dispatch({ type: Types.AUTH_ACTIONS.SetTempProfileData, profileData: data })
    }
  }
}

// SELECTORS:

export const selectUser = (state: RootState) => state.auth.user

export const selectPhoneNumber = (state: RootState) => state.auth.normalizedPhoneNumber

export const selectProfileData = (state: RootState) => state.auth.tempProfileData
