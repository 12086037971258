import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { TranslationPath } from '../i18n/types'

export default function useTypedTranslator() {
  const { t } = useTranslation()
  const typedTranslator = useCallback(
    (path: TranslationPath, options?: { [key: string]: string }) => {
      return t(path, options)
    },
    [t]
  )

  return typedTranslator
}
