export enum ROUTES {
  HOME = '/',
  LOG_IN = '/login',
  EMAIL_LOG_IN = '/login-email',
  PHONE_VERIFICATION = '/phone-verification',
  SIGN_UP = '/signup',
  CODE_REDEEM = '/redeem-code',
  HOW_TO_GET_ACCESS_CODE = '/get-access-code',
  LEARNER_PROFILE = '/learner-profile',
  SUBSCRIPTION_STATUS_CHECK_TUTORIAL = '/how-to-check-subscription-status',
  START_PRACTICING_NOW = 'https://read.withastrid.com/app'
}
