import { WebEventing } from '@astrid/eventing'
import { Analytics } from './analyticsApi'

export enum ScreenNames {
  LogIn = 'Log in',
  LogInPhoneNumber = 'Log in - phone number',
  LogInEmail = 'Log in - email',
  LearnerProfile = 'Learner profile',
  SignUp = 'Sign up',
  RedeemCode = 'Redeem code',
  RedeemCodeSuccess = 'Redeem code success',
  GetAccessCode = 'Get access code',
  GetAccessCodeSuccess = 'Get access code success',
  SubscriptionStatusCheckTutorial = 'Subscription status check tutorial'
}

interface ComponentData {
  componentName: string
  componentValue?: string
}

interface ActionData {
  actionName: string
  actionValue?: string
}

export const trackScreenShown = (screenName: string) =>
  Analytics.track(new WebEventing.Events.WebScreenShown({ url: window.location.pathname, screen_name: screenName }))

const trackInteraction = ({
  interactionType,
  componentType,
  componentName,
  componentValue,
  interactionValue
}: {
  interactionType: WebEventing.WebInteractionType
  componentType: WebEventing.WebComponentType
  interactionValue?: string
  componentName?: string
  componentValue?: string
}) =>
  Analytics.track(
    new WebEventing.Events.WebInteractionTaken({
      url: window.location.pathname,
      interaction_type: interactionType,
      component_type: componentType,
      component_name: componentName || '',
      component_value: componentValue || '',
      interaction_value: interactionValue
    })
  )

export const trackAction = ({ actionName, actionValue }: ActionData) =>
  new WebEventing.Events.WebAction({
    url: window.location.pathname,
    action_title: actionName,
    action_value: actionValue
  })

export const trackButtonClickEvent = ({
  componentName,
  componentValue,
  interactionValue
}: {
  componentName?: string
  componentValue?: string
  interactionValue?: string
}) =>
  trackInteraction({
    interactionType: WebEventing.WebInteractionType.CLICK,
    componentType: WebEventing.WebComponentType.BUTTON,
    componentName,
    componentValue,
    interactionValue
  })

export const trackOptionSelectEvent = ({
  componentName,
  componentValue
}: {
  componentName: string
  componentValue: string
}) =>
  trackInteraction({
    interactionType: WebEventing.WebInteractionType.SELECT,
    componentType: WebEventing.WebComponentType.OPTION,
    componentName,
    componentValue
  })

export const trackTypingEvent = ({ componentName, componentValue }: ComponentData) =>
  trackInteraction({
    interactionType: WebEventing.WebInteractionType.TYPING,
    componentType: WebEventing.WebComponentType.INPUT,
    componentName,
    componentValue
  })
