const en = {
  translation: {
    social_sign_up: {
      continue_with_fb: 'Continue with Facebook',
      continue_with_apple: 'Continue with Apple',
      continue_with_google: 'Continue with Google'
    },
    general: {
      email: 'Email',
      password: 'Password',
      confirm_pass: 'Confirm password',
      phone_number: 'Phone number',
      or: 'or',
      sign_up: 'Create account',
      log_in: 'Log in',
      verify: 'Verify account',
      country: 'Country',
      using_phone_number: 'Using phone number',
      using_email: 'Using email address',
      redeem_code: 'Redeem code',
      verify_phone_number: 'Verification',
      redeem_code_with_phone: 'Enter your phone number to redeem a code.'
    },
    login: {
      log_in_if_you_have_acc: 'Log in if you already have an Astrid account.',
      log_in_using_email: 'Log in using your email adress.',
      new_to_astrid: 'New to Astrid?'
    },
    sign_up: {
      create_profile: 'Create a profile to start learning with Astrid!',
      already_have: 'Already have an account? Log in',
      here: 'here'
    },
    learner_profile: {
      learner_profile: 'Learner profile',
      help_us: 'Help us to personalize the study plan for the learner',
      learner_name: `Learner's name`,
      how_much_eng: 'How much English does the learner know?',
      some_words: 'Some words',
      easy_sentences: 'Easy sentences',
      hard_sentences: 'Hard sentences',
      create_profile: 'Create profile'
    },
    code_redeem: {
      redeem_your_code: 'Redeem your code',
      welcome: 'Welcome to Astrid!',
      do_you_have: 'Do you have access code? Enter it below.',
      accses_code: 'Access code',
      enter_your_code: 'Enter your code here',
      redeem: 'Redeem code',
      i_dont_have: `I don't have an access code`,
      congratulations: 'Congratulations!',
      successfully_redeemed: 'You have successfully redeemed your access code.',
      it_may_take_a_while: `It may take a while before the access is reflected in your account. See how you can check your subcription status`,
      here: 'here',
      start_practicing: 'Start practicing now!'
    },
    check_tutorial: {
      how_to_check: 'How to check your subscription status',
      open_astrid: '1. Open Astrid',
      click_profile: '2. Click on profile icon on the top right corner',
      click_settings: '3. Click on the setting icon on the top right corner',
      open_subscription: '4. Open Subscription under Security',
      check_subscription: '5. Check your subscription status'
    },
    get_access_code: {
      how_to_get_access_code: 'How to get an access code',
      leave_your_number: 'Leave your phone number below and our study advisor will call you back.',
      phone_no: 'Phone number',
      enter_your_phone: 'Enter your phone number here',
      submit: 'Submit',
      buy_through: 'Buy through one of our partners',
      thank_you: 'Thank you!',
      study_advisor_call_back: 'Our study advisor will call you back very soon!'
    },
    footer: {
      questions: '🔥 Questions?',
      contact: 'Contact'
    },
    error: {
      missing_country_code: 'Please select a country code',
      missing_phone_number: 'Please enter your phone number',
      phone_number_not_valid: 'Not a valid phone number',
      general: 'Something went wrong'
    }
  }
}

export default en
