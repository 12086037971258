import { createTheme } from '@material-ui/core/styles'

const bodyFontFamily = 'Lexend, sans-serif'
const headingFontFamily = 'Work Sans, Open Sans'
const bodyFont = `1.125rem/1.8125rem ${bodyFontFamily}`

export const Colors = {
  lightGrey: '#ececec',
  veryLightGrey: '#f8f8f8',
  veryLightPink: '#fff2ee',
  cobaltBlue: '#001593',
  blueGrey: '#f3f7ff',
  cerise: '#e80f62',
  seafoamBlue: '#6fe2dc',
  rosyPink: '#f76988',
  pissYellow: '#edcd11'
}

export const theme = createTheme({
  typography: {
    fontFamily: bodyFontFamily,
    h1: { fontFamily: headingFontFamily },
    h2: { fontFamily: headingFontFamily, fontSize: '1.5rem', marginBottom: '1rem' },
    h3: { fontFamily: headingFontFamily },
    h4: { fontFamily: headingFontFamily },
    h5: { fontFamily: headingFontFamily },
    h6: { fontFamily: headingFontFamily }
  },
  palette: {
    secondary: {
      light: '#5b5d78',
      main: '#333557',
      dark: '#23253c',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#333557',
      secondary: '#848484'
    }
  },
  shape: {
    borderRadius: 14
  },
  props: {
    MuiDrawer: {
      elevation: 0
    },
    MuiAccordion: {
      elevation: 0
    },
    MuiTextField: {
      inputProps: {
        style: {
          font: bodyFont
        }
      }
    },
    MuiInputLabel: {
      style: {
        font: bodyFont
      }
    },
    MuiOutlinedInput: {
      style: {
        font: bodyFont
      }
    },
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
      }
    },
    MuiDialog: {
      root: {
        marginTop: 60
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    }
  }
})
