import React, { useContext } from 'react'

import { AstridLogoBlack, Button } from '@astrid/components'

import AppStoreButton from 'assets/app_store_button.jpg'
import PlayStoreButton from 'assets/play_store_button.jpg'

import { trackButtonClickEvent } from 'analytics/analytics'
import { isAppleDevice } from 'helpers/utils'
import { ROUTES } from 'navigation/types'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectUser } from 'store/services/Auth/auth.reducer'
import { ThunkDispatch } from 'store/types'
import { AppContext } from '../AppContext/AppContext'
import styles from './Header.module.scss'

function Header() {
  const dispatch = useDispatch<ThunkDispatch>()
  const { appStoreUrls, authActions } = useContext(AppContext)
  const user = useSelector(selectUser)
  const onDownloadClick = () => {
    const url = isAppleDevice() ? appStoreUrls.ios : appStoreUrls.android

    trackButtonClickEvent({ componentName: 'Download app button', componentValue: url })

    window.open(url, '_blank')
  }

  const onLogoutClick = () => {
    trackButtonClickEvent({ componentName: 'Logout button' })
    dispatch(authActions.signOut)
  }

  return (
    <header className={styles.container}>
      <div className={styles.inner_container}>
        <Link to={ROUTES.HOME}>
          <AstridLogoBlack className={styles.logo} />
        </Link>

        <div className={styles.right_container}>
          {user ? (
            <Button onClick={onLogoutClick} className={styles.btn} color="secondary">
              Logout
            </Button>
          ) : null}

          <div className={styles.buttons_container}>
            <a href={appStoreUrls.android} className={styles.store_btn} rel="noopener noreferrer" target="_blank">
              <img src={PlayStoreButton} className={styles.store_img} alt="Download from Google Play" />
            </a>
            <a href={appStoreUrls.ios} target="_blank" rel="noopener noreferrer" className={styles.store_btn}>
              <img src={AppStoreButton} className={styles.store_img} alt="Download from App Store" />
            </a>
          </div>

          <div className={styles.download}>
            <Button color="action" onClick={onDownloadClick} className={styles.btn}>
              Download
            </Button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
