import { Button, Typography } from '@astrid/components'
import InlineInput from 'components/InlineInput'
import React, { ChangeEvent, useContext, useState } from 'react'

import useTypedTranslator from 'hooks/useTypedTranslator'

import styles from './GetAccessCode.module.scss'
import { ScreenNames, trackButtonClickEvent, trackScreenShown } from 'analytics/analytics'
import { useTrackScreenShown } from 'hooks'
import { AppContext } from '../../components/AppContext/AppContext'

function GetAccessCode() {
  const { authApi } = useContext(AppContext)
  const t = useTypedTranslator()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)

  useTrackScreenShown(ScreenNames.GetAccessCode)

  const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value)
  }

  const onSubmit = async () => {
    trackButtonClickEvent({ componentName: 'Submit button' })

    if (phoneNumber.length > 3) {
      try {
        await authApi.sendPhoneNumber(phoneNumber)
        setSuccess(true)
        trackScreenShown(ScreenNames.GetAccessCodeSuccess)
      } catch (error) {
        setSuccess(false)
        setError(t('error.general'))
      }
    } else {
      setError(t('error.phone_number_not_valid'))
    }
  }

  return success ? (
    <div className={styles.success}>
      <Typography variant="h1">{t('get_access_code.thank_you')}</Typography>
      <Typography variant="body">{t('get_access_code.study_advisor_call_back')}</Typography>
    </div>
  ) : (
    <div className={styles.container}>
      <Typography variant="h2">{t('get_access_code.how_to_get_access_code')}</Typography>

      <div className={styles.box}>
        <Typography variant="body">{t('get_access_code.leave_your_number')}</Typography>

        {error === null ? null : (
          <Typography variant="body" className={styles.error}>
            <b>{error}</b>
          </Typography>
        )}

        <InlineInput
          label={t('get_access_code.phone_no')}
          name="phoneNumber"
          type="number"
          value={phoneNumber}
          onChange={onPhoneNumberChange}
          className={styles.input}
          placeholder={t('get_access_code.enter_your_phone')}
        />

        <Button color="light-blue" className={styles.button} onClick={onSubmit}>
          {t('get_access_code.submit')}
        </Button>
      </div>
    </div>
  )
}

export default GetAccessCode
