import { combineReducers } from 'redux'
import { RootState } from './types'

import { authReducer, authInitialState } from 'store/services/Auth/auth.reducer'
import { AuthActionsType } from 'store/services/Auth/types'

export const combinedReducers = combineReducers({
  auth: authReducer
})

export const initialRootState: RootState = {
  auth: authInitialState
}

export const rootReducer = (state = initialRootState, action: AuthActionsType) => combinedReducers(state, action)
