import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import { App } from 'App'
import './i18n'

import './index.scss'
import { init } from 'store/services/Sentry'

try {
  const sentryEnv = process.env.REACT_APP_SENTRY_ENVIRONMENT
  if (sentryEnv) {
    init(sentryEnv ?? 'dev')
  }
} catch (err) {
  console.warn('Sentry init failed', err)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
