// Import the functions you need from the SDKs you need
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { AppVariant } from './components/AppContext/AppContext'
// import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const adultFirebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCxfPCpXhB__-LXl2md1b_TPte_KB9w2-8',
  authDomain: 'speak-english-with-astrid.firebaseapp.com',
  databaseURL: 'https://speak-english-with-astrid-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'speak-english-with-astrid',
  storageBucket: 'speak-english-with-astrid.appspot.com',
  messagingSenderId: '220270137240',
  appId: '1:220270137240:web:526019eb8bedec8d43a5ed',
  measurementId: 'G-314M08XS01'
}

const kidFirebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyA3mSOaSNfUw-IrfjklRoEa02NP212UMVs',
  authDomain: 'astrid-infra.firebaseapp.com',
  databaseURL: 'https://astrid-infra-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'astrid-infra',
  storageBucket: 'astrid-infra.appspot.com',
  messagingSenderId: '195291200637',
  appId: '1:195291200637:web:edd2522f98fc78941c4514',
  measurementId: 'G-E818BV7Y9Q'
}

const configs: Record<AppVariant, FirebaseOptions> = {
  [AppVariant.Adult]: adultFirebaseConfig,
  [AppVariant.Kid]: kidFirebaseConfig
}

// Initialize Firebase
export const initalizeForApp = (app: AppVariant) => initializeApp(configs[app])
// const analytics = getAnalytics(app)
