import { Button, Typography } from '@astrid/components'
import { ScreenNames, trackButtonClickEvent, trackScreenShown, trackTypingEvent } from 'analytics/analytics'
import InlineInput from 'components/InlineInput'
import { useTrackScreenShown } from 'hooks'
import useTypedTranslator from 'hooks/useTypedTranslator'
import { ROUTES } from 'navigation/types'
import React, { ChangeEvent, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { selectPhoneNumber } from 'store/services/Auth/auth.reducer'
import { AppContext } from '../../components/AppContext/AppContext'

import styles from './CodeRedeem.module.scss'

function CodeRedeem() {
  const { authApi, appPageUrl } = useContext(AppContext)
  const history = useHistory()
  const t = useTypedTranslator()
  const phoneNumber = useSelector(selectPhoneNumber)

  const [accessCode, setAccessCode] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)

  useTrackScreenShown(ScreenNames.RedeemCode)

  const onAccessCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAccessCode(e.target.value)
  }

  const onGetCodeClick = () => {
    trackButtonClickEvent({ componentName: 'Missing access code button' })
    history.push(ROUTES.HOW_TO_GET_ACCESS_CODE)
  }

  const onRedeemClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    trackButtonClickEvent({ componentName: 'Redeem code button' })

    if (accessCode.length < 1) return
    setError(null)
    try {
      if (phoneNumber) {
        await authApi.claimCodeByPhoneNumber({ phoneNumber, code: accessCode })
      } else {
        await authApi.claimCode(accessCode)
      }
      setSuccess(true)
      trackScreenShown(ScreenNames.RedeemCodeSuccess)
    } catch (error) {
      if (error === 'Access code not found') {
        setError('Access code not found')
      } else {
        setError('Something went wrong. Try again.')
      }
    }
  }

  const handleStartPracticingClick = () => {
    trackButtonClickEvent({ componentName: 'Start practicing now button' })
    window.location.replace(appPageUrl)
  }

  return (
    <div className={styles.container}>
      {success ? (
        <div className={styles.success_container}>
          <Typography variant="h2">{t('code_redeem.congratulations')}</Typography>
          <Typography variant="exerciseS">{t('code_redeem.successfully_redeemed')}</Typography>
          <Typography variant="body" className={styles.margin_xl}>
            {t('code_redeem.it_may_take_a_while')}{' '}
            <Link to={ROUTES.SUBSCRIPTION_STATUS_CHECK_TUTORIAL} className={styles.link}>
              <u>{t('code_redeem.here')}</u>
            </Link>
            .
          </Typography>

          <Button color="green" onClick={handleStartPracticingClick} className={styles.button}>
            {t('code_redeem.start_practicing')}
          </Button>
        </div>
      ) : (
        <>
          <Typography variant="h2">{t('code_redeem.redeem_your_code')}</Typography>

          <div className={styles.box}>
            <Typography variant="exerciseS">{t('code_redeem.welcome')}</Typography>
            <Typography variant="body" className={styles.margin}>
              {t('code_redeem.do_you_have')}
            </Typography>

            {error === null ? null : (
              <Typography variant="body" className={styles.error}>
                <b>{error}</b>
              </Typography>
            )}

            <form>
              <InlineInput
                label={t('code_redeem.accses_code')}
                name="accessCode"
                value={accessCode}
                onChange={onAccessCodeChange}
                onBlur={(event) =>
                  trackTypingEvent({ componentName: 'Access code', componentValue: event.target.value })
                }
                placeholder={t('code_redeem.enter_your_code')}
              />

              <Button color="green" type="submit" className={styles.button} onClick={onRedeemClick}>
                {t('code_redeem.redeem')}
              </Button>
            </form>
          </div>

          {!phoneNumber && (
            <div className={styles.box__bottom_section}>
              <Button color="secondary" className={styles.button__bottom} onClick={onGetCodeClick}>
                {t('code_redeem.i_dont_have')}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CodeRedeem
