class Cookies {
  public constructor(private readonly prefix: string) {}
  private getName(name: string) {
    return this.prefix ? `${this.prefix}-${name}` : name
  }
  public setItem = (name: string, value: string, days: number) => {
    let expires = ''
    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    const domain = process.env.REACT_APP_DOMAIN ? ` Domain=${process.env.REACT_APP_DOMAIN}` : ''
    document.cookie = this.getName(name) + '=' + (value || '') + expires + `; path=/;${domain}`
  }

  public getItem = (name: string) => {
    let nameEQ = this.getName(name) + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  public removeItem = (name: string) => {
    const domain = process.env.REACT_APP_DOMAIN ? ` Domain=${process.env.REACT_APP_DOMAIN}` : ''
    document.cookie = `${this.getName(name)}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;${domain}`
  }
}

export default Cookies
