import { AxiosInstance } from 'axios'
import Cookies from 'helpers/Cookies'
import { CreateProfileData, NormalizedPhoneNumber, SignUpData, User } from './types'
import * as Types from './types'

interface AuthorizationResponse {
  user: User
  token: string
}

const TOKEN_NAME = 'auth_token'

export class AuthTokenAccessor {
  public constructor(private readonly cookies: Cookies) {}
  public getUserToken = () => this.cookies.getItem(TOKEN_NAME)
  public setUserToken = (token: string) => this.cookies.setItem(TOKEN_NAME, token, 3)
  public removeUserToken = () => this.cookies.removeItem(TOKEN_NAME)
}

export class AuthApi {
  public constructor(private readonly api: AxiosInstance) {}

  public getUser = async () => {
    const { data } = await this.api.get<User>('/user')

    return data
  }

  public logIn = async (username: string, password: string) => {
    const { data } = await this.api.post<AuthorizationResponse>('/auth', {
      username: username.trim(),
      password: password.trim()
    })
    return data
  }

  public signup = async (userData: SignUpData) => {
    const { data } = await this.api.post<AuthorizationResponse>('/auth/signup', {
      username: userData.email.trim(),
      password: userData.password.trim(),
      profile: {
        firstName: userData.profile.firstName.trim(),
        school: {
          name: ''
        },
        country: userData.profile.countryCode,
        level: userData.profile.level
      }
    })
    return data
  }

  public socialSignUp = async (
    firebaseIdToken: string,
    profileData: CreateProfileData,
    method: Types.SocialSignUpMethod
  ) => {
    const { data } = await this.api.post<AuthorizationResponse>('/auth/sso/signup', {
      idToken: firebaseIdToken,
      profile: {
        firstName: profileData.firstName.trim(),
        school: {
          name: ''
        },
        country: profileData.countryCode,
        level: profileData.level
      },
      ssoProvider: method
    })

    return data
  }

  public socialLogin = async (firebaseIdToken: string) => {
    const { data } = await this.api.post<AuthorizationResponse>('/auth/sso', { idToken: firebaseIdToken })
    return data
  }

  public sendPhoneNumber = async (phoneNumber: string) => {
    const { data } = await this.api.patch<User>('/user', { phoneNumber })
    return data
  }

  public verifyPhoneNumber = async (phoneNumber: string) => {
    const { data } = await this.api.post<NormalizedPhoneNumber>('/auth/otp/verify', { phoneNumber })
    return data
  }

  public claimCode = async (code: string) => {
    await this.api.post(`/access-code/claim`, { code: code.trim() })
  }

  public claimCodeByPhoneNumber = async ({ phoneNumber, code }: { phoneNumber: string; code: string }) => {
    await this.api.post(`/access-code/claim-by-phone-number`, { phoneNumber, code })
  }

  public sendRecoverPasswordEmail = (email: string) => this.api.get(`/auth/reset-pass/${email}`)
  public setNewPassword = (password: string, token: string) =>
    this.api.post('/auth/reset-pass', { password }, { headers: { authorization: token } })
}
