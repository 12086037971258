import { TranslationBase } from 'i18n/types'

const vi: TranslationBase = {
  translation: {
    social_sign_up: {
      continue_with_fb: 'Tiếp tục với Facebook',
      continue_with_apple: 'Tiếp tục với  Apple',
      continue_with_google: 'Tiếp tục với Google'
    },
    general: {
      email: 'Email',
      password: 'Mật khẩu',
      confirm_pass: 'Xác nhận mật khẩu',
      phone_number: 'Số điện thoại',
      or: 'hoặc',
      sign_up: 'Tạo tài khoản',
      log_in: 'Đăng nhập',
      verify: 'Xác nhận số điện thoại',
      country: 'Quốc gia',
      using_phone_number: 'Dùng số điện thoại',
      using_email: 'Dùng địa chỉ email',
      redeem_code: 'Kích hoạt mã',
      verify_phone_number: 'Xác nhận tài khoản',
      redeem_code_with_phone: 'Nhập số điện thoại để kích hoạt mã.'
    },
    login: {
      log_in_if_you_have_acc: 'Vui lòng đăng nhập nếu bạn đã có tài khoản Astrid',
      log_in_using_email: 'Đăng nhập bằng địa chỉ email.',
      new_to_astrid: 'Chưa có tài khoản?'
    },
    sign_up: {
      create_profile: 'Tạo tài khoản để bắt đầu với Astrid',
      already_have: 'Bạn đã có tài khoản?',
      here: 'Đăng nhập'
    },
    learner_profile: {
      learner_profile: 'Hồ sơ người học',
      help_us: 'Để giúp Astrid cá nhân hóa lộ trình học ',
      learner_name: `Tên người học`,
      how_much_eng: 'Người học có thể sử dụng tiếng Anh ở mức độ nào?',
      some_words: 'Vài từ căn bản',
      easy_sentences: 'Mẫu câu đơn giản',
      hard_sentences: 'Mẫu câu nâng cao',
      create_profile: 'Tạo hồ sơ'
    },
    code_redeem: {
      redeem_your_code: 'Kích hoạt mã',
      welcome: 'Astrid xin chào!',
      do_you_have: 'Bạn đã có mã kích hoạt chưa? Hãy nhập vào ô bên dưới nhé.',
      accses_code: 'Mã kích hoạt',
      enter_your_code: 'Nhập mã kích hoạt ở đây',
      redeem: 'Kích hoạt',
      i_dont_have: `Tôi chưa có mã kích hoạt`,
      congratulations: 'Xin chúc mừng!',
      successfully_redeemed: 'Bạn đã kích hoạt mã thành công ',
      it_may_take_a_while: `Có thể mất một lúc để tài khoản của bạn được cập nhật thông tin kích hoạt. Bạn có thể kiểm tra thông tin tài khoản theo hướng dẫn`,
      here: 'tại đây',
      start_practicing: 'Bắt đầu luyện tập!'
    },
    check_tutorial: {
      how_to_check: 'Cách kiểm tra thông tin tài khoản',
      open_astrid: '1. Mở ứn dụng Astrid',
      click_profile: '2. Bấm vào biểu tượng ở góc phải trên cùng',
      click_settings: '3. Tiếp tục bấm vào biểu tượng trên góc phải trên cùng',
      open_subscription: '4. Mở “Subscription” dưới mục “Security”',
      check_subscription: '5. Bạn sẽ thấy thông tin tài khoản'
    },
    get_access_code: {
      how_to_get_access_code: 'Cách nhận mã kích hoạt',
      leave_your_number: 'Hãy để lại số điện thoại bên dưới nhé. Tư vấn viên Astrid sẽ sớm liên hệ hướng dẫn bạn!',
      phone_no: 'Số điện thoại',
      enter_your_phone: 'Nhập số điện thoại ở đây',
      submit: 'Gửi',
      buy_through: 'Mua mã kích hoạt tại các trang sau',
      thank_you: 'Xin cám ơn!',
      study_advisor_call_back: 'Tư vấn viên Astrid sẽ sớm liên hệ bạn!'
    },
    footer: {
      questions: '🔥 Bạn có câu hỏi?',
      contact: 'Liên hệ ngay'
    },
    error: {
      missing_country_code: 'Vui lòng nhập mã quốc gia',
      missing_phone_number: 'Vui lòng nhập số điện thoại',
      phone_number_not_valid: 'Số điện thoại không tồn tại',
      general: 'Đã xảy ra lỗi'
    }
  }
}

export default vi
