import ApiService from 'api/ApiService'

export const ACCESS_TOKEN_KEY = 'Authorization'

export function createApi(headers: object, accessTokenAccessor: () => string | null) {
  return new ApiService({
    API_URL: process.env.REACT_APP_API_URL,
    DEFAULT_HTTP_HEADERS: {
      'Content-Type': 'application/json',
      ...headers
    },
    ACCESS_TOKEN_KEY: ACCESS_TOKEN_KEY,
    GET_ACCESS_TOKEN: accessTokenAccessor
  })
}
