import Footer from 'components/Footer'
import React, { ReactNode } from 'react'

import styles from './MainWrapper.module.scss'

interface MainWrapperProps {
  children: ReactNode
}

function MainWrapper({ children }: MainWrapperProps) {
  return (
    <main className={styles.container}>
      {children}
      <Footer />
    </main>
  )
}

export default MainWrapper
