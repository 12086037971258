import React from 'react'

import { Typography } from '@astrid/components'

import styles from './Divider.module.scss'

interface DividerProps {
  text: string
  withoutLines?: boolean
  small?: boolean
}

function Divider({ text, withoutLines = false, small = false }: DividerProps) {
  return (
    <div className={small ? styles.container_small : styles.container}>
      {withoutLines ? null : <span className={styles.line} />}
      <Typography variant="body" className={styles.text}>
        {text}
      </Typography>
      {withoutLines ? null : <span className={styles.line} />}
    </div>
  )
}

export default Divider
