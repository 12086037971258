import React from 'react'
import { createApi } from '../../api'
import ApiService from '../../api/ApiService'
import Cookies from '../../helpers/Cookies'
import { AuthApi, AuthTokenAccessor } from '../../store/services/Auth/auth.api'
import { AuthActions } from '../../store/services/Auth/auth.reducer'

export enum AppVariant {
  Kid = 'kid',
  Adult = 'adult'
}

export interface AppContext {
  variant: AppVariant
  urlPrefix: string
  apiService: ApiService
  authApi: AuthApi
  authActions: AuthActions
  appStoreUrls: Record<'android' | 'ios', string>
  appPageUrl: string
}

type VersionHeaderData = {
  type: string
  version: string
  buildNumber: string
  clientName: string
}

const appStoreUrls: Record<AppVariant, Record<'android' | 'ios', string>> = {
  [AppVariant.Kid]: {
    android: 'https://play.google.com/store/apps/details?id=com.teach.withastrid',
    ios: 'https://apps.apple.com/pl/app/astrid-learn-to-speak-english/id1528670445'
  },
  [AppVariant.Adult]: {
    android: 'https://play.google.com/store/apps/details?id=com.astrideducation.speak.english',
    ios: 'https://apps.apple.com/pl/app/astrid-learn-english-faster/id6443731979'
  }
}

const appPageUrls: Record<AppVariant, string> = {
  [AppVariant.Kid]: 'https://read.withastrid.com/app',
  [AppVariant.Adult]: 'https://speak.withastrid.com/'
}

const serializeVersionHeader = (versionHeaderData: VersionHeaderData) =>
  Buffer.from(JSON.stringify(versionHeaderData)).toString('base64')

const httpHeadersForAppName = (clientName: string) => {
  const data = {
    type: 'Access Code Portal',
    version: process.env.REACT_APP_COMMIT_SHA ?? 'n/a',
    buildNumber: '0',
    clientName
  }
  return {
    'x-astrid-version': serializeVersionHeader(data)
  }
}

const grownUpCookies = new Cookies('adult')
const grownUpAuthTokenAccessor = new AuthTokenAccessor(grownUpCookies)
const grownUpApi = createApi(httpHeadersForAppName('adult'), () => grownUpAuthTokenAccessor.getUserToken())
const grownUpAuthApi = new AuthApi(grownUpApi.service)
export const grownUpAppContext: AppContext = {
  variant: AppVariant.Adult,
  urlPrefix: '/',
  apiService: grownUpApi,
  authApi: grownUpAuthApi,
  authActions: new AuthActions(grownUpAuthApi, grownUpAuthTokenAccessor),
  appStoreUrls: appStoreUrls[AppVariant.Adult],
  appPageUrl: appPageUrls[AppVariant.Adult]
}

const kidsCookies = new Cookies('kid')
const kidsAuthTokenAccessor = new AuthTokenAccessor(kidsCookies)
const kidsApi = createApi(httpHeadersForAppName('kid'), () => kidsAuthTokenAccessor.getUserToken())
const kidsAuthApi = new AuthApi(kidsApi.service)
export const kidsAppContext = {
  variant: AppVariant.Kid,
  urlPrefix: '/junior',
  apiService: kidsApi,
  authApi: kidsAuthApi,
  authActions: new AuthActions(kidsAuthApi, kidsAuthTokenAccessor),
  appStoreUrls: appStoreUrls[AppVariant.Kid],
  appPageUrl: appPageUrls[AppVariant.Kid]
}

export const AppContext = React.createContext<AppContext>(grownUpAppContext)
