import React from 'react'
import { Route, RouteProps } from 'react-router'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'

import { selectUser } from 'store/services/Auth/auth.reducer'
import { ROUTES } from './types'

type AuthRouteProps = RouteProps

function AuthRoute(props: AuthRouteProps) {
  const user = useSelector(selectUser)

  if (user) {
    return <Redirect to={ROUTES.CODE_REDEEM} />
  } else {
    return <Route {...props} />
  }
}

export default AuthRoute
