import React, { ChangeEvent, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ct from 'countries-and-timezones'

import { ROUTES } from 'navigation/types'
import { TextInput, Typography, LowLevelIcon, MediumLevelIcon, HighLevelIcon, Button } from '@astrid/components'
import PageWrapper from 'components/PageWrapper'
import Selectable from 'components/Selectable'

import styles from './LearnerProfile.module.scss'
import useTypedTranslator from 'hooks/useTypedTranslator'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from 'store/types'
import { selectProfileData } from 'store/services/Auth/auth.reducer'
import { OnboardingEnglishLevel } from 'store/services/Auth/types'
import {
  ScreenNames,
  trackAction,
  trackButtonClickEvent,
  trackOptionSelectEvent,
  trackTypingEvent
} from 'analytics/analytics'
import { useTrackScreenShown } from 'hooks'
import { AppContext } from '../../components/AppContext/AppContext'

function LearnerProfile() {
  const { authActions } = useContext(AppContext)
  const dispatch = useDispatch<ThunkDispatch>()
  const profileData = useSelector(selectProfileData)
  const [name, setName] = useState(profileData?.firstName || '')
  const [level, setLevel] = useState<OnboardingEnglishLevel | null>(profileData?.level || null)
  const [error, setError] = useState<'name' | 'level' | null>(null)
  const history = useHistory()
  const t = useTypedTranslator()

  useTrackScreenShown(ScreenNames.LearnerProfile)

  const onCreateProfile = () => {
    trackButtonClickEvent({ componentName: 'Create profile button' })

    if (name === '') {
      setError('name')
      return
    }
    if (level === null) {
      setError('level')
      return
    }

    const countryCode =
      (window.Intl && ct.getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)?.id) || 'VI'
    const data = { countryCode: countryCode, firstName: name, level: level }
    dispatch(authActions.setTempProfileData(data))
    trackAction({ actionName: 'Create temporary profile', actionValue: JSON.stringify(data) })
    history.push(ROUTES.SIGN_UP)
  }

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
    setError(e.target.value === '' ? 'name' : null)
  }

  const handleSetLevel = (level: OnboardingEnglishLevel) => {
    trackOptionSelectEvent({ componentName: 'English level', componentValue: level })
    setLevel(level)
  }

  return (
    <PageWrapper>
      <div className={styles.header}>
        <Typography variant="h2">{t('learner_profile.learner_profile')}</Typography>
        <Typography variant="body">{t('learner_profile.help_us')}</Typography>
      </div>

      <Typography variant="h4" className={styles.text}>
        {t('learner_profile.learner_name')}
      </Typography>

      <TextInput
        name="name"
        label={t('learner_profile.learner_name')}
        value={name}
        onChange={onNameChange}
        onBlur={(event) => trackTypingEvent({ componentName: 'Learners name', componentValue: event.target.value })}
        helperText={error ? 'Required' : ''}
        error={error === 'name'}
      />

      <Typography variant="h4" className={styles.text}>
        {t('learner_profile.how_much_eng')}
      </Typography>

      <Selectable
        text={t('learner_profile.some_words')}
        isSelected={level === OnboardingEnglishLevel.Low}
        onClick={() => handleSetLevel(OnboardingEnglishLevel.Low)}
        icon={<LowLevelIcon className={styles.level_icon} />}
      />

      <Selectable
        text={t('learner_profile.easy_sentences')}
        isSelected={level === OnboardingEnglishLevel.Medium}
        onClick={() => handleSetLevel(OnboardingEnglishLevel.Medium)}
        icon={<MediumLevelIcon className={styles.level_icon} />}
      />

      <Selectable
        text={t('learner_profile.hard_sentences')}
        isSelected={level === OnboardingEnglishLevel.High}
        onClick={() => handleSetLevel(OnboardingEnglishLevel.High)}
        icon={<HighLevelIcon className={styles.level_icon} />}
      />

      {error === 'level' ? (
        <Typography variant="body" className={styles.error}>
          <b>Please select the English level</b>
        </Typography>
      ) : null}

      <Button className={styles.button} color="light-blue" onClick={onCreateProfile}>
        {t('learner_profile.create_profile')}
      </Button>
    </PageWrapper>
  )
}

export default LearnerProfile
