import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const ACP_SENTRY_DSN = 'https://318d0976fd00413bac9a38e7ca8527c9@o413852.ingest.sentry.io/6270812'

export function init(environment: string) {
  Sentry.init({
    dsn: ACP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}
