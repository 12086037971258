import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './translations/en'
import vi from './translations/vi'

i18n.use(initReactI18next).init({
  resources: {
    en,
    vi
  },
  compatibilityJSON: 'v3',
  lng: navigator.language.slice(0, 2),
  fallbackLng: 'en',
  debug: false,
  interpolation: { escapeValue: false }
})

export default i18n
