export const handleAuthError = (error: any) => {
  let msg = ''

  if (error === 'USER_NOT_FOUND' || (typeof error === 'string' && error.includes('did not find'))) {
    msg = "User doesn't exist."
  }

  if (error === 'USER_WRONG_CREDENTIALS') {
    msg = 'Invalid email or password.'
  }

  if (error === 'USER_EXISTS') msg = 'An account with this email already exists.'

  if (error === 'SSO_NO_EMAIL_ERROR') msg = 'No email associated with this account. Please try another sign up method.'

  if (error?.code === 'auth/account-exists-with-different-credential') {
    msg = 'Email associated with this account has already been registered.'
  }

  if (msg === '') {
    msg = 'Something went wrong'
  }

  return msg
}

export function isAppleDevice() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'Macintosh'].includes(
      navigator.userAgent
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
