import { Typography } from '@astrid/components'
import useTypedTranslator from 'hooks/useTypedTranslator'
import React from 'react'

import styles from './Footer.module.scss'

function Footer() {
  const t = useTypedTranslator()
  return (
    <div className={styles.container}>
      <Typography variant="body">{t('footer.questions')}</Typography>
      <Typography variant="body">
        {t('footer.contact')} <b>support@astrideducation.com</b>
      </Typography>
    </div>
  )
}

export default Footer
