import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch, BrowserRouter as Router } from 'react-router-dom'

import { ROUTES } from './types'
import { selectUser } from 'store/services/Auth/auth.reducer'

import ProtectedRoute from './ProtectedRoute'
import { CodeRedeem, GetAccessCode, LearnerProfile, Login, SignUp, SubscriptionCheckTutorial } from 'pages'
import Header from 'components/Header'
import PageWrapper from 'components/MainWrapper'

import { ThunkDispatch } from 'store/types'
import AuthRoute from './AuthRoute'
import PhoneVerification from 'pages/PhoneVerification/PhoneVerification'
import EmailLogin from 'pages/EmailLogin/EmailLogin'
import { Analytics } from 'analytics/analyticsApi'
import { Typography } from '@astrid/components'
import useTypedTranslator from 'hooks/useTypedTranslator'
import { AppContext } from '../components/AppContext/AppContext'

export const RouteStack = () => {
  const { urlPrefix, authActions } = useContext(AppContext)
  const user = useSelector(selectUser)
  const t = useTypedTranslator()
  const dispatch = useDispatch<ThunkDispatch>()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setIsLoading(true)
    void (async () => {
      try {
        await dispatch(authActions.getUser())
      } catch (error) {
        const isUnauthorized = typeof error === 'string' && error.includes('401')
        if (!isUnauthorized) {
          setError(t('error.general'))
        }
      } finally {
        setIsLoading(false)
      }
    })()
  }, [dispatch, t, authActions])

  useEffect(() => {
    user?._id && Analytics.identify(user._id, user)
  }, [user, authActions])

  if (isLoading) {
    return <></>
  }

  return (
    <Router basename={urlPrefix}>
      <Header />
      <PageWrapper>
        {error ? (
          <Typography variant="body" style={{ color: 'red' }}>
            {error}
          </Typography>
        ) : null}
        <Switch>
          <AuthRoute exact path={ROUTES.LOG_IN} component={Login} />
          <AuthRoute exact path={ROUTES.PHONE_VERIFICATION} component={PhoneVerification} />
          <AuthRoute exact path={ROUTES.EMAIL_LOG_IN} component={EmailLogin} />
          <AuthRoute exact path={ROUTES.SIGN_UP} component={SignUp} />
          <AuthRoute exact path={ROUTES.LEARNER_PROFILE} component={LearnerProfile} />
          <ProtectedRoute exact path={ROUTES.CODE_REDEEM} component={CodeRedeem} />
          <ProtectedRoute exact path={ROUTES.HOW_TO_GET_ACCESS_CODE} component={GetAccessCode} />
          <ProtectedRoute
            exact
            path={ROUTES.SUBSCRIPTION_STATUS_CHECK_TUTORIAL}
            component={SubscriptionCheckTutorial}
          />
          <Redirect to={user ? ROUTES.CODE_REDEEM : ROUTES.LOG_IN} />
        </Switch>
      </PageWrapper>
    </Router>
  )
}
