import React, { useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import initStore from 'store/store'
import { RouteStack } from 'navigation/RouteStack'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { theme } from 'MuiTheme'
import { AppContext, grownUpAppContext, kidsAppContext } from './components/AppContext/AppContext'
import { Store } from 'redux'
import { initalizeForApp } from './firebase'

const AppTree = ({ context, store }: { context: AppContext; store: Store }) => {
  useEffect(() => {
    // Configure default Firebase instance
    initalizeForApp(context.variant)
  }, [])
  return (
    <AppContext.Provider value={context}>
      <ReduxProvider store={store}>
        <RouteStack />
      </ReduxProvider>
    </AppContext.Provider>
  )
}

export const App = () => {
  const grownUpStore = initStore()
  const kidsStore = initStore()

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route
            path={kidsAppContext.urlPrefix}
            render={() => <AppTree context={kidsAppContext} store={kidsStore} />}
          />
          <Route
            path={grownUpAppContext.urlPrefix}
            render={() => <AppTree context={grownUpAppContext} store={grownUpStore} />}
          />
        </Switch>
      </ThemeProvider>
    </Router>
  )
}
