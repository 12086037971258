import React, { useContext, useState } from 'react'

import { Button, Typography } from '@astrid/components'

import { ScreenNames, trackButtonClickEvent } from 'analytics/analytics'
import Divider from 'components/Divider'
import PageWrapper from 'components/PageWrapper'
import SocialLogInButton from 'components/SocialLogInButton'
import { useTrackScreenShown } from 'hooks'
import useTypedTranslator from 'hooks/useTypedTranslator'
import { ROUTES } from 'navigation/types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'store/types'
import { AppContext } from '../../components/AppContext/AppContext'
import { SocialSignUpMethod } from '../../store/services/Auth/types'
import styles from './Login.module.scss'

function Login() {
  const { authActions } = useContext(AppContext)
  const dispatch = useDispatch<ThunkDispatch>()
  const [error, setError] = useState<string | null>(null)
  const history = useHistory()
  const t = useTypedTranslator()

  useTrackScreenShown(ScreenNames.LogIn)

  const onSocialLoginClick = (variant: SocialSignUpMethod) => async () => {
    trackButtonClickEvent({ componentName: 'Social login button', componentValue: variant })
    try {
      await dispatch(authActions.socialLogin(variant))
    } catch (error) {
      console.log('Social login error:', error)
    }
  }

  const onSignUpClick = () => {
    trackButtonClickEvent({ componentName: 'Sign up button' })
    history.push(ROUTES.LEARNER_PROFILE)
  }

  const onEmailLoginClick = () => {
    trackButtonClickEvent({ componentName: 'Login button', componentValue: 'email' })
    history.push(ROUTES.EMAIL_LOG_IN)
  }

  const onPhoneNumberLoginClick = () => {
    trackButtonClickEvent({ componentName: 'Login button', componentValue: 'phone number' })
    history.push(ROUTES.PHONE_VERIFICATION)
  }

  return (
    <PageWrapper>
      <div className={styles.header}>
        <Typography variant="h1">{t('general.redeem_code')}</Typography>
        <Typography variant="body">{t('login.log_in_if_you_have_acc')}</Typography>
      </div>

      {/* <SocialLogInButton variant="fb" onClick={onSocialLoginClick(SocialSignUpMethod.FACEBOOK)} /> */}
      <SocialLogInButton variant="google" onClick={onSocialLoginClick(SocialSignUpMethod.GOOGLE)} />
      <SocialLogInButton variant="apple" onClick={onSocialLoginClick(SocialSignUpMethod.APPLE)} />

      <Divider text={t('general.or')} withoutLines small />

      <SocialLogInButton variant="phone" onClick={onPhoneNumberLoginClick} />

      <Divider text={t('general.or')} withoutLines small />

      <SocialLogInButton variant="email" onClick={onEmailLoginClick} />

      <Divider text={t('login.new_to_astrid')} />

      <Button type="submit" color="light-blue" className={styles.button} onClick={onSignUpClick}>
        {t('general.sign_up')}
      </Button>
    </PageWrapper>
  )
}

export default Login
