import { Typography, CheckIcon } from '@astrid/components'
import React, { ReactNode } from 'react'

import styles from './Selectable.module.scss'

interface SelectableProps {
  isSelected: boolean
  onClick: () => void
  icon?: ReactNode
  text: string
}

function Selectable({ icon, onClick, text, isSelected = false }: SelectableProps) {
  return (
    <div className={isSelected ? styles.container__selected : styles.container} onClick={onClick}>
      <div className={styles.inner_container}>
        {icon}
        <Typography variant="body">{text}</Typography>
      </div>
      {isSelected ? <CheckIcon /> : null}
    </div>
  )
}

export default Selectable
