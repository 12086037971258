export enum OnboardingEnglishLevel {
  Low = 'A1.1',
  Medium = 'A2.1',
  High = 'B1.1'
}

export type UserProfile = {
  firstName: string
  lastName: string
  globalScore: number
  isPlacementTestCompleted: boolean
  shouldDisplayRatePopup: boolean
  introStoryCompleted: boolean
  country: string
  grade: string
  avatar?: string
}

export interface User {
  _id: string
  username: string
  profile: UserProfile
  phoneNumber?: string
}

export interface NormalizedPhoneNumber {
  normalizedPhoneNumber: string | null
}

export enum SocialSignUpMethod {
  GOOGLE = 'google',
  APPLE = 'apple',
  FACEBOOK = 'facebook'
}

export type CreateProfileData = {
  countryCode: string
  firstName: string
  level: OnboardingEnglishLevel
}

export type SignUpData = {
  email: string
  password: string
  profile: CreateProfileData
}

// REDUX TYPINGS:

export enum AUTH_ACTIONS {
  ClearUser = 'auth/clear_user',
  LoadUser = 'auth/load_user',
  ClearPhoneNumber = 'auth/clear_phone_number',
  LoadPhoneNumber = 'auth/load_phone_number',
  SetTempProfileData = 'auth/set_temp_profile_data'
}

export type ActionLoadUser = {
  type: AUTH_ACTIONS.LoadUser
  user: User
}

export type ActionClearUser = {
  type: AUTH_ACTIONS.ClearUser
}

export type ActionClearPhoneNumber = {
  type: AUTH_ACTIONS.ClearPhoneNumber
}

export type ActionLoadPhoneNumber = {
  type: AUTH_ACTIONS.LoadPhoneNumber
  normalizedPhoneNumber: string | null
}

export type ActionSetTempProfileData = {
  type: AUTH_ACTIONS.SetTempProfileData
  profileData: CreateProfileData | null
}

export type AuthActionsType =
  | ActionClearUser
  | ActionLoadUser
  | ActionClearPhoneNumber
  | ActionLoadPhoneNumber
  | ActionSetTempProfileData
