import React, { useMemo } from 'react'
import { Typography, AppleLogo, FacebookLogo, GoogleLogo } from '@astrid/components'

import styles from './SocialLogInButton.module.scss'
import useTypedTranslator from 'hooks/useTypedTranslator'
import { ReactComponent as PhoneSVG } from '../../assets/phone.svg'
import { ReactComponent as EmailSVG } from '../../assets/email.svg'

interface SocialLogInButtonProps {
  onClick: () => void
  variant: 'fb' | 'apple' | 'google' | 'phone' | 'email'
}

function SocialLogInButton({ onClick, variant }: SocialLogInButtonProps) {
  const t = useTypedTranslator()
  const data = useMemo(() => {
    switch (variant) {
      case 'apple':
        return {
          icon: <AppleLogo width={30} height={30} />,
          text: t('social_sign_up.continue_with_apple')
        }
      case 'fb':
        return {
          icon: <FacebookLogo width={30} height={30} />,
          text: t('social_sign_up.continue_with_fb')
        }
      case 'google':
        return {
          icon: <GoogleLogo width={30} height={30} />,
          text: t('social_sign_up.continue_with_google')
        }
      case 'phone':
        return {
          icon: <PhoneSVG width={24} height={24} />,
          text: t('general.using_phone_number')
        }
      case 'email':
        return {
          icon: <EmailSVG width={24} height={24} />,
          text: t('general.using_email')
        }
    }
  }, [t, variant])

  return (
    <div className={styles.container} onClick={onClick} role="button">
      {data.icon}
      <Typography variant="body" className={styles.text}>
        {data.text}
      </Typography>
    </div>
  )
}

export default SocialLogInButton
